<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de proveedores"
          icon="fas fa-dolly"
          btn_name="proveedor"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'lg'"
          :button_new="true"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="myButtons"
        />
      </CCol>
    </CRow>
    <!-- <CContainer> -->
    <CRow class="text-center">
      <CCol lg="6" md="6" sm="12" xs="12"
        ><CBadge color="success"
          ><h4>
            <strong> Proveedores Activos : {{ cp_count_active }}</strong>
          </h4>
        </CBadge>
      </CCol>
      <CCol lg="6" md="6" sm="12" xs="12"
        ><CBadge color="secondary">
          <h4>
            <strong>
              Proveedores Inactivos : {{ cp_count_inactive }}
            </strong>
          </h4>
        </CBadge></CCol
      >
      <!-- <CCol lg="4" md="4" sm="12" xs="12">
          Total proveedores : {{ data_status_inactive + data_status_active }}
        </CCol> -->
    </CRow>
    <!-- </CContainer> -->

    <!-- modal -->
    <CModalForm
      :size="'xl'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol lg="4" md="4" sm="12" xs="12">
          <CInput
            label="Documento"
            placeholder="Digite documento"
            v-model="supplier.document"
            v-on:keyup.enter="mtd_search_document"
          />
        </CCol>
        <!-- :disabled="modal.action == 'store'?false:true " -->
        <CCol lg="4" md="4" sm="12" xs="12">
          <CInput
            label="Nombres"
            placeholder="Digite nombre de proveedor"
            v-model="supplier.name"
            :disabled="boo_novo"
          />
        </CCol>
        <CCol lg="4" md="4" sm="12" xs="12">
          <CInput
            label="Apellidos"
            placeholder="Digite apellido de proveedor"
            v-model="supplier.last_name"
            :disabled="boo_novo"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <cSelectForm
            label="Seleccione un pais"
            :options="data_countries"
            placeholder="Escoja un pais"
            :value.sync="supplier.country_id"
            @change="mtd_select_country"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <cSelectForm
            label="Seleccione una region"
            :options="data_regions"
            placeholder="Escoja una region"
            :value.sync="supplier.region_id"
            @change="mtd_select_region"
            :disabled="boo_country"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <cSelectForm
            label="Seleccione una provincia"
            :options="data_province_filter"
            placeholder="Escoja una provincia"
            :value.sync="supplier.province_id"
            @change="mtd_select_province"
            :disabled="boo_country"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <cSelectForm
            label="Seleccione un distrito"
            :options="data_disctrict_filter"
            placeholder="Escoja un ditrito"
            :value.sync="supplier.district_id"
            :disabled="boo_country"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Base"
            placeholder="Digite base"
            v-model="supplier.base"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Dirección"
            placeholder="Digite dirección"
            v-model="supplier.address"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Celular/telefono"
            placeholder="Digite celular/telefono"
            v-model="supplier.phone"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <cSelectForm
            label="Seleccione un genero"
            :options="data_gender"
            placeholder="Escoja un genero"
            :value.sync="supplier.gender"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            type="date"
            label="Fecha de nacimiento"
            v-model="supplier.date_of_birth"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            type="date"
            label="Asociado desde"
            v-model="supplier.associated_from"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput label="Ruc" placeholder="Digite ruc" v-model="supplier.ruc" />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Superficie total"
            placeholder="Digite superficie total(Ha)"
            v-model="supplier.area_total"
          />
        </CCol>
        <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Superficie de producción(Ha)"
            placeholder="Digite superficie de producción"
            v-model="supplier.area_production"
          />
        </CCol>
        <CCol lg="6" md="6" sm="12" xs="12">
          <label for="">Elegir certificaciones</label>
          <v-select
            :options="data_certificaciones"
            multiple
            v-model="supplier.certification"
            placeholder="Seleccione certificacion(es)"
          />
        </CCol>
        <!-- <CCol lg="3" md="3" sm="12" xs="12">
          <CInput
            label="Producción de café pergamino(Tm)"
            placeholder="Digite producción"
            v-model="supplier.area_pergamino"
          />
        </CCol> -->
        <CCol lg="3" md="3" sm="12" xs="12">
          <cSelectForm
            label="Seleccione un estado"
            :options="data_state"
            placeholder="Escoja un estado"
            :value.sync="supplier.state"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Proveedor"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>

    <!-- <CListGroup >
      <CListGroupItem active class="align-items-start"><span>Total proveedores activos y desactivados</span> </CListGroupItem>
      <CListGroupItem>Proveedores activados : {{data_status_active}}</CListGroupItem>
      <CListGroupItem>Proveedores desactivados : {{data_status_inactive}}</CListGroupItem>
      <CListGroupItem>Total proveedores {{data_status_inactive+data_status_active}}</CListGroupItem>
    </CListGroup> -->
  </div>
</template>


<script>
const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "document", label: "Documento", _style: "width:20%;" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "status", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete, cSelectForm,vSelect },
  data() {
    return {
      prefix: "supplier",
      fields,
      data: [],
      data_countries: [],
      data_certificaciones:[],
      boo_country: true,
      data_regions: [],
      data_provinces: [],
      data_province_filter: [],
      data_disctrict: [],
      data_disctrict_filter: [],
      data_status_active: "",
      data_status_inactive: "",
      data_gender:[
        {value:"FEMENINO",label:"Femenino"},
        {value:"MASCULINO",label:"Masculino"},
        {value:"OTROS",label:"Otros"},
      ],
      data_state: [
        { value: 1, label: "Activo" },
        { value: 2, label: "Inactivo" },
      ],
      myButtons: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      boo_novo: true,
      supplier: {
        id: "",
        document: "",
        name: "",
        last_name: "",
        country_id: "",
        region_id: "",
        province_id: "",
        district_id: "",
        base: "",
        address: "",
        phone: "",
        gender: "",
        date_of_birth: "",
        associated_from: "",
        ruc: "",
        area_total: "",
        area_production: "",
        area_pergamino: "",
        state: "",
        certification:[]
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_count_active: function(){
      let cc=0;
      this.data.forEach(element => {
        if (element.status =='Activo') {
          cc += 1;
        }
      });
      return cc;
    },
    cp_count_inactive: function(){
      let cc=0;
      this.data.forEach(element => {
        if (element.status !='Activo') {
          cc += 1;
        }
      });
      return cc;
    },
    cp_button: function () {
      //   if (this.supplier.name != "" && this.supplier.description) return false;
      //   return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.map((item, order) => {
            return { ...item, order };
          });
          let cont = 0;
          let cont1 = 0;

          response.data.forEach((estatus) => {
            if (estatus.status == "Activo") {
              cont += 1;
            } else {
              cont1 += 1;
            }
          });
          this.data_countries = response.data_countries;
          this.data_regions = response.data_regions;
          this.data_provinces = response.provinces;
          this.data_disctrict = response.data_disctrict;
          this.data_certificaciones = response.data_certificaciones;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_search_document: function () {
      this.get({
        url:
          this.$store.getters.get__url + "/novoapi/" + this.supplier.document,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.boo == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "El DNI se encontró en la base de datos",
            });
            this.supplier.name = response.name;
            this.supplier.last_name = response.last_name;
            this.boo_novo = true;
          } else if (response.boo == 1) {
            bus.$emit("alert", {
              color: "warning",
              message: "DNI no existe, digite nuevamente el dni",
            });
            this.supplier.name = "";
            this.supplier.last_name = "";
            this.boo_novo = true;
          } else if (response.boo == 3) {
            bus.$emit("alert", {
              color: "danger",
              message: "Ha ocurrido un problema, ingrese otra consulta  ",
            });
            this.supplier.name = "";
            this.supplier.last_name = "";
            this.boo_novo = true;
          } else {
            bus.$emit("alert", {
              color: "success",
              message: "El DNI se encontró con la api",
            });
            this.supplier.name = response.name;
            this.supplier.last_name = response.last_name;
            this.boo_novo = true;
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo proveedor")
        : (this.modal.title = "Editar proveedor");
      action == "store"
        ? (this.supplier = {
            id: "",
            document: "",
            name: "",
            last_name: "",
            country_id: "",
            region_id: "",
            province_id: "",
            district_id: "",
            base: "",
            address: "",
            phone: "",
            gender: "",
            date_of_birth: "",
            associated_from: "",
            ruc: "",
            area_total: "",
            area_production: "",
            area_pergamino: "",
            state: "",
            certification:[]
          })
        : (this.supplier = this.supplier);
      if (action != "store") {
        if (this.supplier.country_id == 173) {
          let temp_region = this.supplier.region_id.length == 5? this.supplier.region_id : this.supplier.region_id.substring(1,this.supplier.region_id.length);
          this.supplier.region_id = parseInt(temp_region);
          this.mtd_select_country();
          this.mtd_select_region();
          this.mtd_select_province();
        }
        /** certification */
        let cert = this.supplier.certification;
        let ar = cert.split(',');
        let temp=[];
        for (let index = 1; index < ar.length; index++) {
          const id_cer = ar[index];
          this.data_certificaciones.forEach(element => {
            if (element.value == id_cer) {
              temp.push(element);
            }
          });
        }
        this.supplier.certification = temp;
      }
    },
    mtd_select_country: function () {
      this.boo_country = this.supplier.country_id != 173 ? true : false;
      if (this.modal.action == 'store' ) {
        this.supplier.region_id = "";
        this.supplier.district_id = "";
        this.supplier.province_id = "";
      }
    },
    mtd_select_region: function () {
      this.data_province_filter = [];
      this.data_provinces.forEach((element) => {
        let prov =
          this.supplier.region_id.length == 5
            ? "0" + this.supplier.region_id
            : this.supplier.region_id;
        if (element.region == prov) {
          this.data_province_filter.push(element);
        }
      });
      this.supplier.province_id = parseInt(this.supplier.province_id);
    },
    mtd_select_province: function () {
      this.data_disctrict_filter = [];
      this.data_disctrict.forEach((element) => {
        let prov =
          this.supplier.province_id.length == 5
            ? "0" + this.supplier.province_id
            : this.supplier.province_id;
        if (element.province == prov) {
          this.data_disctrict_filter.push(element);
        }
      });
      this.supplier.district_id = parseInt(this.supplier.district_id);
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.supplier,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            if (response.state == 0) {
              if (this.data.length == 0) {
                this.mtd_getdata();
              } else {
                this.data.push(response.data[0]);
              }
              message = "REGISTRADO CORRECTAMENTE";
            } else {
              color="danger";
              message="EL DOCUMENTO INGRESADO YA EXISTE";
            }
          } else {
            this.data.forEach((element) => {
              if (element.Id == response[0].Id) {
                element.name = response[0].name;
                element.status = response[0].status;
              }
              message = "EDITADO CORRECTAMENTE";
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
            description: "",
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.supplier = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>